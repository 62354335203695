import {
  mapLocaleToIntlLocale,
  parseChunkImport,
} from '@commercetools-frontend/i18n';
import { reportErrorToSentry } from '@commercetools-frontend/sentry';

const loadMessages = (locale) => {
  const intlLocale = mapLocaleToIntlLocale(locale);
  switch (intlLocale) {
    case 'de':
      return import(/* webpackChunkName: "local-i18n-de" */ './data/de.json');
    case 'es':
      return import(/* webpackChunkName: "local-i18n-es" */ './data/es.json');
    case 'fr-FR':
      return import(
        /* webpackChunkName: "local-i18n-fr-FR" */ './data/fr-FR.json'
      );
    default:
      return import(/* webpackChunkName: "local-i18n-en" */ './data/en.json');
  }
};

// eslint-disable-next-line import/prefer-default-export
const loadI18n = async (locale) => {
  try {
    const chunkImport = await loadMessages(locale);
    return parseChunkImport(chunkImport);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      `Something went wrong while loading the core messages for ${locale}`,
      error
    );
    reportErrorToSentry(
      new Error(
        `Something went wrong while loading the core messages for ${locale}`
      ),
      { extra: error }
    );
    return {};
  }
};

export default loadI18n;
